.loginContainerContent {
  margin-top: -2%; /* Adjust as necessary */
}

.text-left {
  text-align: left;
}

.loginContainerContent h1 {
  margin-bottom: 1rem;
}

.loginContainerContent p {
  margin-bottom: 1rem;
}

.loginContainerContent a {
  text-decoration: none;
  color: var(--primary-button-bg-color);
  display: flex;
  align-items: center;
  font-size: 17px;
}

.loginContainerContent a:hover {
  color: var(--primary-button-bg-color);
}

.loginContainerContent a .icon {
  margin-right: 0.5rem;
}
