/* Add this CSS file in the same directory as your component */

/*
.footer {
    background-color: #f8f9fa; 
    padding: 1rem;
    border-top: 1px solid #dee2e6; 
    width: 100%; 
    box-sizing: border-box;
  }
*/





  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f8f9fa; /* Adjust color as needed */
    padding: 1rem;
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: flex-start;
  }
  
  
  .footer-content {
    display: flex;
    align-items: center; /* Vertically center the items */
    justify-content: flex-start; /* Align content to the left */
  }
  
  .footer-copy {
    margin-right: 2rem; /* Space between copyright and links */
  }
  
  .footer-links {
    display: flex;
    gap: 0.5rem; /* Space between the links */
  }
  
  .footer-links a {
    text-decoration: none;
    color: #007bff; /* Link color */
  }
  
  .footer-links a:hover {
    text-decoration: underline;
  }
  